import { Outlet } from 'react-router-dom'
import Header from '../Components/Header'
import Footer from '../Components/Footer';
import { Component } from 'react';


class RootLayout extends Component {

  state = {
    navBar: ['Home', 'Faq', 'Sign-Up', 'Sign-In', 'DashBoard']
  }

  render() {
     return (
    <div>
        <Header navBar = {this.state.navBar} />
       
        <Outlet/>

        <Footer />
        
    </div>
    
  )
  }
 
}

export default RootLayout
