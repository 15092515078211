


const CoinGeckoWidget = () => {
  return (
    <div>
     <canvas>
      
      <script type="text/javascript" src="https://files.coinmarketcap.com/static/widget/coinPriceBlock.js"></script><div id="coinmarketcap-widget-coin-price-block" coins="1,1027,825" currency="USD" theme="dark" transparent="true" show-symbol-logo="true"></div>
      
      
     </canvas>
      
    </div>
  );
};

export default CoinGeckoWidget;