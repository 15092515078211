import logo from '..//assets/Logo.png'
import {FaBars} from 'react-icons/fa'
import '../Styles/Header.css'
import { NavLink } from 'react-router-dom'
import { getAuth } from 'firebase/auth'


const Header = ({navBar}) => {

    const auth = getAuth()
    const user = auth.currentUser;
   

    const NavBar = user ? navBar.map((nav, key) => {
        return <li key={key}><NavLink to={key === 0 ? '/' : `/${nav}`}>{nav}</NavLink></li>
    }) : navBar.filter(nav => {
        return nav !== 'DashBoard'
    }).map((nav, key) => {
        return <li key={key}><NavLink to={key === 0 ? '/' : `/${nav}`}>{nav}</NavLink></li>
    })


    const handleOnClick = (e) => {
        let tog = document.querySelector('.navbar')
        tog.classList.toggle('show')
    } 
  
    return (
        <header>
            <div>
                <img src={logo} alt="Logo" /> 
                <li><FaBars className='icon' onClick={handleOnClick}/></li>
            </div>
           
            <nav className="navbar show">
                <ul>
                  {NavBar}
                </ul>
            </nav>
        </header>
    )
}

export default Header;