const VerifyEmail = () => {
    return(
        <div className="verify">
            <div className="verify-container">
                <h3>Verify Email</h3>
                <span>We have sent a link to your email click on it, to verify your email</span>
            </div>
        </div>
    )
}

export default VerifyEmail;