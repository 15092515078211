import { useState, useEffect } from 'react'
import '../Styles/dashBoard.css'
import {FaUserCircle, FaAngleLeft, FaAngleRight} from 'react-icons/fa'
import {
    onAuthStateChanged, getAuth
} from 'firebase/auth'
import { collection, doc, onSnapshot, getDoc} from 'firebase/firestore'

import db from '../firebase.js'
import emailjs from 'emailjs-com'

export default function User ()  {

    const auth = getAuth()
    const emailjs_userId = 'VK53TvyAUAwd40mZs';
    const emailjs_serviceId = 'service_qxxdbog';
    const emailjs_userTemplate = 'template_hj7ndto';

    const [open, setOpen] = useState(false);
    const [userData, setUserData] = useState ({
        name: 'john stone',
        img: '',
        capital: 0,
        earning: 0,
        pack: 'none',
        roi: 0

    })

    const userRef = collection(db, 'Users')

    onAuthStateChanged(auth, (user) => {
        if (user) {
            onSnapshot(doc(userRef, auth.currentUser.displayName), doc => {
                setUserData({
                     name: doc.data().firstName + ' ' + doc.data().lastName,
                     roi: doc.data().roi,
                     capital: doc.data().capital,
                     earning: doc.data().earning,
                     pack: doc.data().package,
                     img: auth.currentUser.photoURL
                })
            })
        }else {
            console.log('not logged in.')
        }
    })


    const {name, img, capital, earning, pack, roi} = userData;


    const [prevValue, setPrevValue] = useState(null)

    const [newValue, setNewValue] = useState(null)

   

    useEffect(() => {
        const currentUser = auth.currentUser;

        const docRef = doc(db, 'Users', currentUser.displayName)

        let unsubscribe;


    
        const getData = async () => {

          const initial = await getDoc(docRef);


          setPrevValue(initial.data().earning)

          unsubscribe = onSnapshot(docRef, (snap) => {
            if (snap.exists()) {
              const current = snap.data().earning;


              setNewValue(current)

              if(current !== initial && current > prevValue) {
                const emailParams = {
                    from_name: 'SmartFunds Team',
                    to_name: currentUser.displayName,
                    to_email: currentUser.email,
                    message: `You Have Earned $ ${current}, check your account and comfirm the funds \n \n Thanks For Trusting SmartFunds. `

                }

                emailjs.send(emailjs_serviceId, emailjs_userTemplate, emailParams, emailjs_userId) 
                setPrevValue(current)   
              }

            }

          });
        }

        getData();

        return () => {
          if(unsubscribe) {
            unsubscribe();
          }
        }
        }, [prevValue]); 

    const [prevCapital, setPrevCapital] = useState(capital);
    const [newCapital, setNewCapital] = useState(0)
         

    useEffect(() => {
        const currentUser = auth.currentUser;

        const documentRef = doc(db, 'Users', currentUser.displayName)

        let unsub;

        const gettingData = async () => {
          const initData = await getDoc(documentRef);


          setPrevCapital(initData.data().capital)


          unsub = onSnapshot(documentRef, (snap) => {
            if(snap.exists()) {
              const currData = snap.data().capital

              setNewCapital(currData)

              if(currData !== initData && currData > prevCapital) {
                const emailParams = {
                    from_name: 'SmartFunds Team',
                    to_name: currentUser.displayName,
                    to_email: currentUser.email,
                    message: `Your Account Have Been Funded With $ ${currData}, check your account and comfirm the funds \n \n Thanks For Trusting SmartFunds. `

                }

                emailjs.send(emailjs_serviceId, emailjs_userTemplate, emailParams, emailjs_userId)
                setPrevCapital(currData)
              }
            }
          });
        }

        gettingData();

        return () => {
          if(unsub) {
            unsub();
          }
        }
    
        }, [prevCapital]); 



    return (
        <div className={open ? 'User-Container' : 'userContainer'}>

            {open ? <FaAngleRight className='arrBtn' onClick={() => {
                setOpen(false) }
            }/> : <FaAngleLeft className='arrBtn' onClick={() => {
                setOpen(true) }
                } />
                }
            <div className="User-Info">
                {
                
                img !== null
                
                ? <div className='userIdentity'>
                    <img src={img} alt='User-Pic' className='user-icon' /> 
                    <span>{name}</span>
                </div>
                : 
                <div className='userIdentity'>
                    <FaUserCircle className='user-icon' />
                    <span>{name}</span>
                </div>
                }
                
                <h4>
                    Welcome To Your Journey To Financial Freedom.
                </h4>
            </div>

            <main className="User-Content">
                <div className="capital">
                    <p>
                        $ {capital}
                    </p>
                    <span>Capital</span>
                    
                </div>

                <div className="earning">
                    <p>
                        $ {earning}
                    </p>
                    <span>Earning</span>
                </div>

                <div className="subInfo">
                    <p>
                        <span>Package :</span>
                        <span>{pack}</span>
                    </p>

                    <p>
                        <span>
                            Expected Roi :
                        </span>
                        <span>
                            {roi} %
                        </span>
                    </p>
                </div>

                <div className="userButton">
                    <button>Withdraw</button>
                    <button>Deposit</button>
                </div>
            </main>

        </div>
    )
}
